import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import $ from "jquery";
import Start from "./galleryPages/Start";
import Contact from "./pages/Contact";
import Photos from "./galleryPages/Photos"
import Loading from "./components/Loading";
import FullImage from "./components/FullImage";

const Gallery = ({history}) => {
  const [errors, setErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(null);

  var handleError = (msg) => {
    if (msg) {
      setErrors(errors => [
        ...errors,
        msg
      ]);
    }
  };

  var closePreview = () => {
    setPreview(null);
  }

  return (
    <div className="root" tabIndex="-1">
      <Router>
        {errors && errors.length > 0 && 
        <div className="error__wrapper">
          {
            errors.map((e, i) => {
              return <div className="error__item" key={'name_' + i}>
                {e}
              </div>;
            })
          }
          <div className="error__close" onClick={() => {setErrors([]);}}>X</div>
        </div>
      }
        <Switch>
          <Route exact path="/">
            <Start onError={handleError} onLoading={setLoading} />
          </Route>
          <Route path="/hochzeit">
            <Photos onError={handleError} onLoading={setLoading} preview={setPreview} gallery="210821" title="Hochzeit" />
          </Route>
          <Route path="/zivil">
            <Photos onError={handleError} onLoading={setLoading} preview={setPreview} gallery="120821" title="Zivile Trauung" />
          </Route>
          <Route path="/challenge">
            <Photos onError={handleError} onLoading={setLoading} preview={setPreview} gallery="fotochallenge" title="Foto-Challenge" />
          </Route>
          <Route path="/kontakt">
            <Contact />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      </Router>
      {loading && 
        <Loading/>
      }
      {preview && 
          <FullImage src={preview.src} title={preview.title} close={closePreview} />
      }
    </div>
  );
};

export default Gallery;
