import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import localStorageAvailable from "../util/localStorageUtility"
import "../styles/stage.scss";

const Start = (props) => {
  let history = useHistory();

  const [code, setCode] = useState("");
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (window.location.search) {
      var urlParams = new URLSearchParams(window.location.search);
      var initialCode = urlParams.get("code");
      if (initialCode && initialCode.length > 0) {
        if (localStorageAvailable()) {
          localStorage.setItem("photoCode", initialCode);
          window.history.pushState({}, document.title, window.location.pathname);
        }
      }
    }
    verifyCode(true);
  }, []);

  var verifyCode = (auto) => {
    var headers = {
      "X-AuthCode": ""
    };
    //debugger;
    if (auto === true) {
      if (localStorageAvailable()) {
        var photoCode = localStorage.getItem("photoCode");
        if (photoCode) {
          headers["X-AuthCode"] = photoCode;
        } 
      }
    } else if (code) {
      headers["X-AuthCode"] = code;
    }

    $.ajax({
      method: "POST",
      url: window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/VerifyAuthentication" : "/api/VerifyAuthentication",
      crossDomain: true,
      headers: headers
    }).then((response) => {
      setAuthenticated(true);
      if (!auto && localStorageAvailable()) {
        localStorage.setItem("photoCode", code);
      }
    }).catch((error) => {
      setAuthenticated(false);
      if (!auto && localStorageAvailable()) {
        localStorage.removeItem("photoCode");
        props.onError("Der Code stimmt leider nicht mit der Angabe auf der Karte überein.");
      }
    });
  };

  return (
    <div className="start">
      <div>
        <h1>Wir blicken zurück...</h1>
        <p>...auf ein wundervolles Hochzeitsfest, an einem wunderschönen Tag, mit wunderbar lieben Gästen.</p>
        <p>Schön warst du dabei oder hast uns in irgend einer Form bei unserer Hochzeit unterstützt. <b>Vielen Dank!</b></p>
        <p>Nebst all den schönen Erinnerungen, die uns für immer bleiben werden, sind rund um das Hochzeitsfest auch einige schöne Bilder entstanden, die wir gerne mit dir teilen würden.</p>
        {!authenticated && 
          <form className="form" onSubmit={(e) => { e.preventDefault(); verifyCode(false); return false; }}>
            <p>Um Zugriff auf die Bildergallerie zu haben, gib bitte in untenstehendem Feld den Code ein, wie wir auf der Dankeskarte vermerkt haben:</p>
            <label>
              <div>Code:</div>
              <input type="text" placeholder="Code" name="code" value={code} onChange={e => setCode(e.target.value)} />
            </label>

            <button type="submit" onClick={void(0)}>Bilder anzeigen</button>
          </form>
        }
        {authenticated && 
          <div>
            <p>Wir haben unsere Bilder in folgende Gallerien aufgeteilt:</p>
            <div className="gallery__wrapper">
              <div className="gallery">
                <div className="gallery__image gallery" aria-label="Fotos vom Hochzeitsfest" onClick={() => {history.push("/hochzeit");}}>
                    <img src="/photos/public/hochzeit.jpg?small" alt="Fotos vom Hochzeitsfest" />
                </div>
                <div className="gallery__image gallery" aria-label="Fotos von der zivilen Trauung" onClick={() => {history.push("/zivil");}}>
                    <img src="/photos/public/zivil.jpg?small" alt="Fotos von der zivilen Trauung" />
                </div>
                <div className="gallery__image gallery" aria-label="Gästefotos der Foto-Challenge" onClick={() => {history.push("/challenge");}}>
                    <img src="/photos/public/challenge.jpg?small" alt="Gästefotos der Foto-Challenge" />
                </div>
              </div>
            </div>
          </div>
        }
        </div>
    </div>
  );
};

export default Start;
