import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../styles/stage.scss";

const Contact = (props) => {

  return (
    <div className="start">
      <div>
        <p>
          <b>Simone Berger</b> &middot; <a href="tel:+41795906884">079 590 68 84</a> &middot; <a href="mailto:simone@berger-schaer.ch">simone@berger-schaer.ch</a><br /><br />
          <b>Christian Berger</b> &middot; <a href="tel:+41792308853">079 230 88 53</a> &middot; <a href="mailto:christian@berger-schaer.ch">christian@berger-schaer.ch</a>
        </p>
      </div>
    </div>
  );
};

export default Contact;
