import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import $ from "jquery";
import localStorageAvailable from "../util/localStorageUtility"

const Photos = (props) => {
    let history = useHistory();

    const [photos, setPhotos] = useState([]);

    useEffect(() => {
        loadPhotos(props.gallery);
    }, []);

    var loadPhotos = (gallery) => {
        props.onLoading(true);

        var headers = {
            "X-AuthCode": ""
        };

        if (localStorageAvailable()) {
            var photoCode = localStorage.getItem("photoCode");
            if (photoCode) {
                headers["X-AuthCode"] = photoCode;
            } 
        }
        $.ajax({
            method: "GET",
            url: (window.location.hostname.toLowerCase() === "localhost" ? "https://berger-schaer.local/api/photos" : "/api/photos") + "?gallery=" + gallery,
            crossDomain: true,
            contentType: "application/json; charset=utf-8",
            headers: headers
        }).then((response) => {
            setPhotos(response);
            props.onLoading(false);
        }).catch((error) => {
            props.onError("Die Bilder können gerade nicht geladen werden.");
            props.onLoading(false);
        });
    };

    var setActivePhoto = (e, photo, i) => {
        var $clickedNode = $(e.currentTarget);
        if ($clickedNode.hasClass("gallery__image--active")) {
            $clickedNode.removeClass("gallery__image--active");
        } else {
            $(".gallery__image--active").removeClass("gallery__image--active");
            $(e.currentTarget).addClass("gallery__image--active");
        }
    };

    var downloadImg = (url) => {
        var link = document.createElement('a');
        link.href = url;
        link.target = "_blank"
        link.download = url.substring(url.lastIndexOf("/") + 1);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return <div className="gallery__wrapper">
            <div className="home-link" title="Zurück zur Übersicht" onClick={() => { history.push("/"); }}></div>
            <div className="gallery__hint">
                <h1>{props.title}</h1>
                <p><b>Tipp:</b> Um die Fotos jeweils vergrössert zu sehen, klicke/tippe sie an, damit dir weitere Optionen angezeigt werden. Beispielsweise kannst du das Bild dann im Vollbildmodus öffnen oder es direkt herunterladen.</p>
            </div>
            <div className="gallery">
                {
                photos.map((p, i) => {
                    return <div key={p.name} className={'gallery__image gallery__image--' + p.orientation } onClick={(e) => {setActivePhoto(e, p);}} aria-label={p.description}>
                        <img src={p.name + "?small"} alt={p.description} />
                        <span className="gallery__image__enlarge" onClick={() => { props.preview({src: p.name + "?large", title: p.description}); }} title="Grossansicht öffnen"></span>
                        <span className="gallery__image__download" onClick={() => { downloadImg(p.name); }} title="Bild in Originalgrösse herunterladen"></span>
                    </div>
                })
                }
            </div>
        </div>;
}

 export default Photos;