const localStorageAvailable = () => {
    if (typeof localStorage !== 'undefined') {
        try {
            localStorage.setItem('feature_test', 'yes');
            if (localStorage.getItem('feature_test') === 'yes') {
                localStorage.removeItem('feature_test');
                return true;
            }
        } catch(e) {
            // localStorage is disabled
        }
      }
      return false;
}

export default localStorageAvailable;